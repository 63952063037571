import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const firebaseConfig = {
  apiKey: "AIzaSyAwdCiO8TlvMAyZzO7xjhgroaz_WhtM6ZI",
  authDomain: "lippulaiva-lounas.firebaseapp.com",
  projectId: "lippulaiva-lounas",
  storageBucket: "lippulaiva-lounas.appspot.com",
  messagingSenderId: "111321602958",
  appId: "1:111321602958:web:632a3c06f7e50f009422df",
  measurementId: "G-WLT6QPTJPG",
};

export const app = initializeApp(firebaseConfig);
